import React, { useState, useEffect } from "react";

import Autocomplete from "../../../components/Autocomplete";
import { listsSelectors, useSelector } from "../../../state";
import { gematriya } from "../../../lib";
import { Option } from "../../../components/Select/Select";
import { AutocompleteChangeReason } from "@mui/material/Autocomplete";
import { AutocompleteValue } from "@mui/material/useAutocomplete";

export function MonthsAutocomplete({
  value,
  onChange,
  label = "",
  startMonthId = undefined,
  endMonthId = undefined,
  error = false,
  autocompleteProps = {},
  months = undefined,
  setFieldValue = undefined,
}: {
  value;
  onChange?;
  label?;
  startMonthId?: number | undefined;
  endMonthId?: number | undefined;
  error?: boolean;
  autocompleteProps?;
  months?;
  setFieldValue?;
}) {
  const [monthOption, setMonthOption] = useState<Option | null>(null);

  const _months =
    months ||
    useSelector(listsSelectors.months).filter(
      (m) =>
        (!startMonthId || m.id >= startMonthId) &&
        (!endMonthId || m.id <= endMonthId),
    );
  const monthOptions = _months.map((m) => ({
    label: `${m.hebrew || ""} ${gematriya(m.year_id.toString()) || ""}`,
    value: m.id.toString(),
  }));
  useEffect(() => {
    setMonthOption(
      monthOptions.find((m) => m.value === value?.toString()) || null,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const handleSelectMonth = (
    event: React.SyntheticEvent,
    selection: AutocompleteValue<Option, boolean, boolean, boolean>,
    reason: AutocompleteChangeReason,
  ) => {
    event?.stopPropagation();

    if (reason === "selectOption" && !!selection) {
      const option = selection as Option;
      setFieldValue("month_id", option.value);
    }

    if (reason === "clear") {
      setFieldValue("month_id", "");
    }
  };

  return (
    <Autocomplete
      value={monthOption || ""}
      name="month_id"
      id="month_id"
      onChange={
        onChange ? onChange : setFieldValue ? handleSelectMonth : () => {}
      }
      options={[{ label: "", value: "" }, ...monthOptions]}
      placeholder={label}
      error={error}
      {...autocompleteProps}
    />
  );
}
