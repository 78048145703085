import React, { FC } from "react";
import {
  BottomSection,
  Page,
  TopSection,
} from "../styles/GridCardsSearch.styles";
import {
  bottomSectionStyle,
  btnStyle,
  nameEng,
  rolesStyle,
  section,
  topSectionStyle,
} from "./ProfilePage.styles";
import SimplePageHeader from "../../../components/SimplePageHeader";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useProfilePage } from "./useProfilePage";
import { LocalPhoneIcon, LocationOnIcon } from "../../../components";

const Section: FC<{ children: any }> = ({ children }) => {
  return <Box sx={section}>{children}</Box>;
};

const ProfilePage: FC = () => {
  const { user, handleEditBtn } = useProfilePage();

  return (
    <Page>
      <TopSection style={topSectionStyle}>
        <SimplePageHeader title={"My account"} />
      </TopSection>

      <BottomSection style={bottomSectionStyle}>
        <Stack direction={"row"} justifyContent={"space-between"} spacing={4}>
          <Section>
            <Typography variant={"h4"}>{user?.nameYiddish}</Typography>

            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              spacing={1}
              mt={"3px"}
            >
              <Typography variant={"body2"} sx={nameEng}>
                {user?.nameEnglish}
              </Typography>

              <Typography variant={"body2"} sx={rolesStyle}>
                {`Roles  ${user?.roles}`}
              </Typography>
            </Stack>

            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              spacing={1}
              mt={4}
            >
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                spacing={2}
                flexBasis={"50%"}
              >
                <LocationOnIcon sx={{ width: "20px" }} />
                <Box flexGrow={1}>
                  <Typography variant={"body2"}>{user?.address}</Typography>
                  <Typography variant={"body2"}>
                    {user?.neighborhood}
                  </Typography>
                </Box>
              </Stack>

              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                spacing={2}
                flexBasis={"50%"}
              >
                <LocalPhoneIcon sx={{ width: "20px" }} />
                <Box flexGrow={1}>
                  <Typography variant={"body2"}>Phone {user?.phone}</Typography>
                  <Typography variant={"body2"}>Cell {user?.cell}</Typography>
                </Box>
              </Stack>
            </Stack>

            <Button variant={"contained"} sx={btnStyle} onClick={handleEditBtn}>
              edit
            </Button>
          </Section>

          {/* <Section>
            <Typography variant={"h4"}>Login details</Typography>
            <Typography variant={"subtitle2"} color={"black"} mt={3}>
              To update your login details, click update below
            </Typography>

            <Button
              variant={"contained"}
              sx={btnStyle}
              onClick={handleUpdateBtn}
            >
              update
            </Button>
          </Section> */}
        </Stack>
      </BottomSection>
    </Page>
  );
};

export default ProfilePage;
