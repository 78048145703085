import React, { useEffect, useState } from "react";

import {
  Box,
  Collapse,
  Dialog,
  DialogTitle,
  List,
  ListItemButton,
  ListItemText,
  Button,
  DialogActions,
  Divider,
} from "@mui/material";
import {
  buildStudentEnrollmentData,
  buildStudentEditGeneralData,
} from "./utils";
import { formatDateISO } from "../../../lib";
import { adminActions, customerActions } from "../../../state";
import { useDispatch } from "react-redux";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ExpandLess } from "@mui/icons-material";
import { CheckIcon } from "../../../components";

import { btnSx } from "../StudentPage/EnterTestModal/EnterTestModal.styles";
import EditPaymentSettings from "../CustomerPage/EditPaymentSettings";

import ConfirmModal from "../ManageGroup/ConfirmModal";
import { useReactivateStudentModal } from "./useReactivateStudentModal";
import { ReactivateStudentSteps } from "./ReactivateStudentSteps";

export default function ReactivateStudentModal({ open, onClose }) {
  const {
    formikGeneral,
    enrollmentHooks,
    formikBilling,
    onboardingFormik,
    student,
    setYiddishDate,
    yiddishDate,
    customersTotalDue,
    remainingCredit,
    appliedCredit,
    studentsTotalDue,
    chaburahPrice,
    cardData,
    credit,
    upcomingBill,
    studentsUpcomingFees,
    studentsBillingInfo,
    discount,
    discountReason,
    discountType,
    multi,
    paid,
    unpaid,
  } = useReactivateStudentModal();

  const dispatch = useDispatch();
  const [paymentMethodOpen, setPaymentMethodOpen] = useState(false);
  const [shouldSubmitDiscount, setShouldSubmitDiscount] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState<boolean | null>(false);

  const customerId = formikGeneral.values.customer_id;
  const toggleStepDone = (index, done) => {
    setStepsStates({
      ...stepsStates,
      [index]: { ...(stepsStates[index] || {}), done: done },
    });
  };
  const steps = ReactivateStudentSteps({
    formikGeneral,
    enrollmentHooks,
    formikBilling,
    onboardingFormik,
    setYiddishDate,
    yiddishDate,
    toggleStepDone,
    customersTotalDue,
    remainingCredit,
    studentsTotalDue,
    chaburahPrice,
    cardData,
    credit,
    upcomingBill,
    studentsUpcomingFees,
    studentsBillingInfo,
    setShouldSubmitDiscount,
    setPaymentMethodOpen,
    discount,
    discountReason,
    discountType,
    multi,
    paid,
    unpaid,
    student,
  });
  const initialState = {};
  for (let index = 0; index < steps.length; index++) {
    initialState[index] =
      index === 0 ? { open: true, done: false } : { open: false, done: false };
  }

  const [stepsStates, setStepsStates] = useState<any>(initialState);
  const firstSubmitStep = 3;

  useEffect(() => {
    const { chaburah_id, teacher_id, month_id } = enrollmentHooks.values;
    if (chaburah_id && teacher_id && month_id) {
      toggleStepDone(0, true);
    } else {
      toggleStepDone(0, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    enrollmentHooks.values.chaburah_id,
    enrollmentHooks.values.teacher_id,
    enrollmentHooks.values.month_id,
    enrollmentHooks.values,
  ]);

  let lastDoneStep = 0;
  for (const [key, value] of Object.entries(stepsStates)) {
    if ((value as any).done && +key > lastDoneStep) {
      lastDoneStep = +key;
    }
  }
  const toggleCollapse = (indexToCollapse) => {
    if (!stepsStates[indexToCollapse]?.done) {
      return;
    }
    if (lastDoneStep >= firstSubmitStep && indexToCollapse <= firstSubmitStep) {
      return;
    }
    setStepsStates({
      ...stepsStates,
      [indexToCollapse]: {
        ...(stepsStates[indexToCollapse] || {}),
        open: !stepsStates[indexToCollapse]?.open,
      },
    });
  };

  const firstSubmit = async () => {
    await formikGeneral.setFieldValue("status_id", 4);

    const data = buildStudentEditGeneralData(
      formikGeneral.values,
      student?.id.toString() || "",
    );
    const updateStudentSucces = await dispatch(
      adminActions.updateStudent(student?.id.toString() || "", {
        ...data,
        ...onboardingFormik.values,
        status_id: 4,
      }),
    );
    if (!updateStudentSucces) {
      return false;
    }

    const d = {
      ...buildStudentEnrollmentData(enrollmentHooks.values),
      is_reactivate: true,
    };
    const enrollmentSucces = await dispatch(
      adminActions.updateStudentEnrollment(student?.id.toString() || "", d),
    );
    if (!enrollmentSucces) {
      return false;
    }
    dispatch(customerActions.getCustomerBill(student?.id, student?.month_join));
    return true;
  };

  const secondSubmit = async () => {
    if (!student?.id) {
      return;
    }
    const { default_payment_method, date, note, terms, credit_add } =
      formikBilling.values;
    const d:
      | {
          discount?: string | null;
          discount_type?: string;
          id?: null | number;
          reason?: null | string;
        }
      | any = discount ? formikBilling.values : {};
    const discountFields = shouldSubmitDiscount
      ? {
          discount: d.discount ? parseFloat(d.discount) : 0,
          discount_type: d.discount_type,
          reason: d.reason ?? "",
          end_month_id: d.end_month_id ? +d.end_month_id : null,
        }
      : {};
    const data = {
      default_payment_method,
      payment_method_id: cardData?.[0]?.payment_method_id,
      amount: studentsTotalDue,
      date: date ? formatDateISO(date) : null,
      note,
      terms,
      credit_add: +(credit_add || 0),
      credit_use: appliedCredit,
      is_reactivate: true,
      ...discountFields,
    };
    await dispatch(adminActions.addStudentBillingCredit(student.id, data));
  };

  const continueToNextStep = () => {
    let newState = { ...stepsStates };
    for (let index = 0; index < Object.values(stepsStates).length; index++) {
      if (newState[index].done) {
        newState = {
          ...newState,
          [index]: { ...newState[index], open: false, done: true },
        };
      } else {
        const wasOpen = stepsStates[index].open;
        if (wasOpen && steps[index].setTouched) {
          steps[index].setTouched();
        }
        newState = {
          ...newState,
          [index]: {
            ...newState[index],
            done:
              index === 1 || steps[index].optional
                ? true
                : newState[index].done,
            open: true,
          },
        };
        setStepsStates(newState);

        break;
      }
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      sx={{ "& .MuiDialog-paper": { maxWidth: "800px" } }}
    >
      <DialogTitle>
        Reactivate {student?.first_name_yiddish} {student?.last_name_yiddish}
      </DialogTitle>
      <Box padding={4} paddingTop={1}>
        <List>
          {" "}
          {steps.map((step, i) => {
            const { index } = step;
            const done = !!stepsStates[index]?.done;
            return (
              <Box key={i}>
                <ListItemButton
                  key={i}
                  onClick={() => {
                    toggleCollapse(index);
                  }}
                >
                  <Box
                    marginRight={1}
                    padding={1}
                    borderRadius={50}
                    width={30}
                    height={30}
                    border={`1px solid ${done ? "#128E56" : "#072556"}`}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    color={done ? "#128E56" : "#1F5DC3"}
                    fontWeight="bold"
                  >
                    {done ? <CheckIcon /> : index + 1}
                  </Box>
                  <ListItemText
                    color="#072556"
                    sx={{
                      "& .MuiTypography-root": {
                        color: "#072556",
                        fontSize: "14px",
                        fontWeight: 600,
                      },
                    }}
                  >
                    {step.name}
                  </ListItemText>
                  {stepsStates[index]?.open ? (
                    <ExpandLess
                      onClick={(e: React.MouseEvent<SVGSVGElement>) => {
                        e.stopPropagation();
                        toggleCollapse(index);
                      }}
                    />
                  ) : (
                    <ExpandMore
                      onClick={(e: React.MouseEvent<SVGSVGElement>) => {
                        e.stopPropagation();
                        toggleCollapse(index);
                      }}
                    />
                  )}
                </ListItemButton>

                <Collapse
                  in={stepsStates[index]?.open}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box paddingX={6}>{step.component}</Box>
                </Collapse>
              </Box>
            );
          })}
        </List>

        <EditPaymentSettings
          showModal={paymentMethodOpen}
          customerId={customerId ? +customerId : 0}
          studentId={student?.id ?? 0}
          name={student?.first_name_yiddish ?? ""}
          hideDiscount
          onClose={async () => {
            await dispatch(
              customerActions.getCustomerBillingDetails(+customerId),
            );
            await dispatch(
              customerActions.getCustomerBill(student?.id, student?.month_join),
            );
            setPaymentMethodOpen(false);
          }}
        />
      </Box>

      <Divider
        variant="middle"
        sx={{ border: "1px solid #DDE3EB", mb: 2, ml: 0, mr: 0 }}
      />
      <ConfirmModal
        modal={confirmCancel}
        onClose={(d) => {
          if (d) {
            setConfirmCancel(null);
            onClose();
          } else {
            setConfirmCancel(null);
          }
        }}
        message="Are you sure you want to leave?"
      />

      <DialogActions sx={{ paddingBottom: 2, paddingRight: 2 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            if (lastDoneStep <= firstSubmitStep) {
              setConfirmCancel(true);
            } else {
              onClose();
            }
          }}
          sx={{ ...btnSx, marginRight: 2 }}
        >
          {lastDoneStep > firstSubmitStep ? "Close & Save as Draft" : "Cancel"}
        </Button>

        <Button
          variant="contained"
          color={"primary"}
          sx={btnSx}
          onClick={async () => {
            const readyForFirstSubmit =
              lastDoneStep === firstSubmitStep &&
              stepsStates[firstSubmitStep].open;

            if (readyForFirstSubmit) {
              const success = await firstSubmit();
              if (!success) {
                return;
              }
            }
            const finished = Object.values(stepsStates).every(
              (s: any) => s.done,
            );
            if (finished) {
              await secondSubmit();
              onClose();
            } else {
              continueToNextStep();
            }
          }}
        >
          {stepsStates[firstSubmitStep].open
            ? "save and continue"
            : stepsStates[steps.length - 1].open
            ? "process payment & reactivate"
            : "continue"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
