import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { authActions, store } from "./state";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { REACT_APP_MUI_LICENSE } from "./config";
import { defaultTheme } from "./themes";
import { App } from "./App";
import { init } from "./components";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "../src/assets/css/styles.css";

function main() {
  init();
  const dispatch: any = store.dispatch;
  dispatch(authActions.load({}));
  render();
}

LicenseInfo.setLicenseKey(REACT_APP_MUI_LICENSE!);

function render() {
  ReactDOM.render(
    <ThemeProvider theme={defaultTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ReduxProvider store={store}>
          <CssBaseline />
          <App />
        </ReduxProvider>
      </LocalizationProvider>
    </ThemeProvider>,
    document.getElementById("root"),
  );
}

main();
